// @ts-check

class MemoryCache {
  /**
   * @type {Object.<string, MemoryCache.CacheItem>}
   */
  items = {};

  /**
   * @param {string} key the key to look for in the cache map
   * @returns {boolean} true or false
   */
  contains(key) {
    return Object.keys(this.items).includes(key);
  }

  valid(key) {
    return this.contains(key) && this.items[key].expires > Date.now();
  }

  get(key) {
    return this.items[key];
  }

  set(key, value) {
    this.items[key] = value;
  }

  unset(key) {
    delete this.items[key];
  }
}

/**
 * @property {number} expires a timestamp after which this CacheItem is no longer valid
 * @property {*} data The data returned from the server for this endpoint
 * @property {boolean} loading whether the item is currently being loaded
 * @property {*} error Data returned or generated from an error response
 */
MemoryCache.CacheItem = class {
  expires = 0;
  data = {};
  loading = false;
  error = {};
};

export default MemoryCache;
