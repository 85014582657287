/* eslint-disable no-bitwise */
import { MonitorsVersion } from '@/common/enums';

import { normalizeDomainName } from '../../common/helpers';
import { Endpoints, ActionList } from '../api-data';
import { useData } from './util.js';

const WARNING_CODES = {
  MISSING_FIREWALL: 2,
  OUTDATED_SOFTWARE: 32,
  NO_WWW_REMOTE_SCANNNER_ERROR: 64,
  WWW_FAIL_REMOTE_SCANNNER_ERROR: 128,
  BLACKLISTED: 1024,
  REMOTELY_DETECTABLE_MALWARE: 2048,
  SSS_DETECTABLE_MALWARE: 4096,
  NEWSCAN: 4,
};

const WARNING_CODES_V2 = {
  HAS_FIREWALL: 1024,
  OUTDATED_SOFTWARE: 128,
  FAILED: 512,
  BLACKLISTED: 2048,
  MALWARE: 536870912,
  NEWSCAN: 2,
};

/**
 * Determines the warning states based on the provided monitoring data.
 *
 * @param {Object} monitoring - The monitoring data
 *
 * @returns {Object} An object containing the warning states.
 * @returns {boolean} .isNewMonitoringScan - Whether a new monitoring scan is in progress.
 * @returns {boolean} .isLastMonitoringScanSuccessful - Whether the last monitoring scan was successful.
 * @returns {boolean} .isBlacklisted - Whether the system is blacklisted.
 * @returns {boolean} .isInfected - Whether the system is infected.
 * @returns {boolean} .isOutdated - Whether the system's software is outdated.
 * @returns {boolean} .isFirewallMissing - Whether the firewall is missing.
 */
const warningStates = (monitoring) => {
  const version = monitoring?.version;
  const warnLevel = monitoring?.warnLevel || monitoring?.warnlevel;
  const codes =
    version === MonitorsVersion.TWO ? WARNING_CODES_V2 : WARNING_CODES;

  return {
    isNewMonitoringScan: monitoring && !!(warnLevel & codes.NEWSCAN),
    isLastMonitoringScanSuccessful:
      !monitoring ||
      !(
        warnLevel & codes.FAILED || // V2 Check
        warnLevel & codes.NO_WWW_REMOTE_SCANNNER_ERROR ||
        warnLevel & codes.WWW_FAIL_REMOTE_SCANNNER_ERROR
      ),
    isBlacklisted: monitoring && !!(warnLevel & codes.BLACKLISTED),
    isInfected:
      monitoring &&
      !!(
        warnLevel & codes.MALWARE || // V2 Check
        warnLevel & codes.REMOTELY_DETECTABLE_MALWARE ||
        warnLevel & codes.SSS_DETECTABLE_MALWARE
      ),
    isOutdated: monitoring && !!(warnLevel & codes.OUTDATED_SOFTWARE),
    isFirewallMissing:
      monitoring &&
      !!(
        !(warnLevel & codes.HAS_FIREWALL) || // V2 Check
        warnLevel & codes.MISSING_FIREWALL
      ),
  };
};

/**
 * @extends {Array}
 */
class MonitoringSitesList extends Array {
  /**
   * @param {array} sites A list of sites
   * @param {string} server The monitoring server URL
   * @param {string} version The Monitors version
   */
  constructor(sites, server, version) {
    super(...sites);

    /**
     * @type {string} The monitoring server URL
     */
    this.server = server;
    this.version = version;
  }
}

const useMonitoringSites = () => {
  return useData({
    endpoint: Endpoints.MonitoringSites,
    action: ActionList,
    initialValue: new MonitoringSitesList([], '', MonitorsVersion.UNKNOWN),
    outputFilter: (output) => {
      if (!output || !output.sites) {
        return new MonitoringSitesList([], '', MonitorsVersion.UNKNOWN);
      }

      const sites = [];
      Object.keys(output.sites).forEach((sitename) => {
        sites.push({
          domain: normalizeDomainName(sitename),
          ...output.sites[sitename],
          version: output.version,
          synced: output.synced,
        });
      });

      return new MonitoringSitesList(sites, output.server, output?.version);
    },
  });
};

const useMonitoringServer = () => {
  return useData({
    endpoint: Endpoints.MonitoringSites,
    action: ActionList,
    initialValue: '',
    outputFilter: (output) => {
      return output && output.server ? output.server : '';
    },
  });
};

export default MonitoringSitesList;
export {
  useMonitoringSites,
  useMonitoringServer,
  MonitoringSitesList,
  WARNING_CODES,
  WARNING_CODES_V2,
  warningStates,
};
