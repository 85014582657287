const FirewallSetupStep = {
  START: 1,
  ACTIVATE: 2,
  VERIFY: 3,
  ANYCASTIP: 4,
  UPDATEDNS: 5,
  FINALIZE: 6,
  COMPLETE: 7,
};

const FirewallSetupDnsMode = {
  DNSAPI: 1,
  DOMAINCONNECT: 2,
  MANUAL: 3,
};

const FirewallSetupDnsApiMode = {
  VERIFICATION: 'verification',
  A: 'A',
};

const FirewallStatus = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  LIVE: 'Live',
};

const FirewallCloudflareStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  COMPLETE: 'COMPLETE',
};

const FirewallVersion = {
  ONE: '1.0',
  TWO: '2.0',
};

const FirewallLegacySuccessIndicator = {
  ONE_IS_SUCCESS: 1,
  ZERO_IS_SUCCESS: 0,
};

const InvalidFirewallSetupResponseCode = {
  PROXIED: 'proxied',
  IPPRIVATE: 'ipprivate',
  IPINVALID: 'ipinvalid',
  GODADDY: 'godaddy',
  BANNED: 'banned',
  RATELIMITCA: 'ratelimit_ca',
};

const FirewallSiteRequestAction = {
  LIST_SITES: 'domains_activation_status',
  ACTIVATE: 'godaddy_activate_firewall',
  DOMAIN_CONNECT: 'domain_connect',
  CLEAR_CACHE: 'clear_cache',
  GET_DOMAIN_ACTIVATION_STATUS: 'domains_activation_status',
  GET_SITE_HOSTING_IP: 'site_hosting_ip',
  GET_AUDIT_TRAILS: 'audit_trails',
  GET_EMAIL_REPORTS_STATUS: 'email_reports_status',
  GET_SCREENSHOT: 'screenshot',
  UPDATE_EMAIL_REPORTS_SETTINGS: 'email_reports_settings',
  GET_SETTINGS: 'show_settings',
  UPDATE_SETTING: 'update_setting',
  ADD_CERTIFICATE: 'add_certificate',
};

const MonitorsVersion = {
  ONE: '1.0',
  TWO: '2.0',
  UNKNOWN: 'UNKNOWN',
};

const MonitorsSiteRequestAction = {
  LIST_SITES: 'overview',
};

const MonitorsSiteSyncStatus = {
  NO_V1_SITES: 'NO_V1_SITES',
  NOT_SYNCED: 'NOT_SYNCED',
  SYNCED: 'SYNCED',
  UNKNOWN: 'UNKNOWN',
};

const PageSizes = {
  MONITORING_SITES_PAGE_SIZE: 1000,
};

const MonitoringV1WarnLevels = {
  OK: 0,
  MISSING_FIREWALL: 2,
  OUTDATED_SOFTWARE: 32,
  NO_WWW_REMOTE_SCANNNER_ERROR: 64,
  WWW_FAIL_REMOTE_SCANNNER_ERROR: 128,
  BLACKLISTED: 1024,
  REMOTELY_DETECTABLE_MALWARE: 2048,
  SSS_DETECTABLE_MALWARE: 4096,
  NEWSCAN: 4,
};

const MonitoringV2WarnLevels = {
  OK: 0,
  HAS_FIREWALL: 1024,
  OUTDATED_SOFTWARE: 128,
  FAILED: 512,
  BLACKLISTED: 2048,
  MALWARE: 536870912,
  NEWSCAN: 2,
};

const MonitoringWarnLevelMessages = {
  FAILED: 'FAILED',
  MALWARE: 'MALWARE',
  BLACKLISTED: 'BLACKLISTED',
  OUTDATED_SOFTWARE: 'OUTDATED SOFTWARE',
  MISSING_FIREWALL: 'MISSING FIREWALL',
  UNKNOWN: 'UNKNOWN',
};

const SUCURI_PLID = '565123';

module.exports = {
  FirewallVersion,
  FirewallCloudflareStatus,
  FirewallSetupStep,
  FirewallSetupDnsApiMode,
  FirewallSetupDnsMode,
  FirewallStatus,
  FirewallLegacySuccessIndicator,
  InvalidFirewallSetupResponseCode,
  FirewallSiteRequestAction,
  MonitorsVersion,
  MonitorsSiteRequestAction,
  MonitorsSiteSyncStatus,
  PageSizes,
  MonitoringV1WarnLevels,
  MonitoringV2WarnLevels,
  MonitoringWarnLevelMessages,
  SUCURI_PLID,
};
