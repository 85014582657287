// @ts-check
import fetch from 'cross-fetch';

import { logger } from '@/common/initAPM';

import MemoryCache from './memory-cache';

// Unique symbols for Endpoint action methods. These allow consumers of an endpoint
// to specify the method they wish to invoke without having to use a string literal
// like 'list' or 'get', which is prone to typing errors
const ActionList = Symbol('ActionList');
const ActionGet = Symbol('ActionGet');
const ActionUpdate = Symbol('ActionUpdate');
const ActionDelete = Symbol('ActionDelete');

/**
 * @typedef {Object} EndpointOpts
 * @property {string} path The base path for this resource
 * @property {MemoryCache} cache The cache implementation
 * @property {Number} TTL the amount of time, in seconds, an endpoint's response should be cached
 * @property {boolean} [poll] Whether to poll the endpoint periodically
 */

/**
 * @typedef {Object} EndpointCallback
 * @property {String} id A unique ID to ensure callbacks are only delivered to right place
 * @property {function(any): void} callback A callback function
 */

class Endpoint {
  /**
   * @constructor
   * @param {EndpointOpts} opts Endpoint constructor options
   */
  constructor(opts) {
    this.path = opts.path;
    this.cache = opts.cache;
    this.TTL = opts.TTL || 0;
    this.poll = opts.poll || false;
  }

  /**
   * @type {EndpointCallback[]}
   */
  subscribers = [];

  /**
   * Format a path template into an absolute URL using an object full of parameters
   * @param {String} path The path template to format
   * @param {Object} params Parameters to interpolate into the path or append as query string
   * @returns {String} The formatted absolute URL
   */
  formatPath(path, params) {
    params = { ...params };
    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        path.includes(`{${key}}`)
      ) {
        path = path.replace(`{${key}}`, params[key]);
        delete params[key];
      }
    }

    const qs = new URLSearchParams(params);

    if (typeof window !== 'undefined') {
      return `${window.location.protocol}//${window.location.host}${path}${
        qs.toString() ? '?' + qs.toString() : ''
      }`;
    }

    return `${path}${qs.toString() ? '?' + qs.toString() : ''}`;
  }

  /**
   *
   * @param {Object|null} maybeError A value that we will attempt to parse for an error
   * @returns {Error} An error parsed from maybeError, or a fallback
   */
  parseResponseError(maybeError) {
    const fallback = 'an unexpected error occurred';
    if (!maybeError) {
      return new Error(fallback);
    }

    return new Error(maybeError.error || maybeError.message || fallback);
  }

  /**
   * Makes an HTTP request and caches the result
   * @param {object} param0 Request options
   * @param {string} param0.method The HTTP request method
   * @param {string} param0.path The URL path
   * @param {object} param0.params The HTTP request params
   * @returns {Promise<MemoryCache.CacheItem>}
   */
  // eslint-disable-next-line max-statements
  async request({ method, path, params }) {
    params = params || {};
    const requestKey = this.serializeRequest({ method, path, params });
    if (method === 'GET' && this.cache.valid(requestKey)) {
      return { ...this.cache.get(requestKey) };
    }

    this.cache.set(requestKey, {
      data: null,
      loading: true,
      expires: Date.now() + this.TTL * 1000,
      error: null,
    });

    var res, parsed;
    try {
      res = await fetch(this.formatPath(path, params), {
        method: method,
        credentials: 'same-origin',
      });
    } catch (e) {
      const log = { stack: e.stack, data: { message: e.stack?.output?.error } };
      logger.error(e.message || e.stack?.output?.error, log);
      return {
        data: null,
        loading: false,
        expires: 0,
        error: e.message,
      };
    }

    try {
      parsed = await res.json();
    } catch (e) {
      parsed = null;
    }

    if (res.status >= 400) {
      return {
        data: null,
        loading: false,
        expires: 0,
        error: this.parseResponseError(parsed),
      };
    }

    // Cache the value of GET responses
    if (method === 'GET') {
      this.cache.set(requestKey, {
        ...this.cache.get(requestKey),
        data: parsed,
        loading: false,
        expires: Date.now() + this.TTL * 1000,
        error: null,
      });
      return { ...this.cache.get(requestKey) };
    }

    return {
      data: parsed,
      loading: false,
      expires: 0,
      error: null,
    };
  }

  /**
   * A Symbol identifier alias for the list method
   * @param {Object} params An object of request parameters
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async [ActionList](params) {
    return this.list(params);
  }

  /**
   * A RESTful list operation, listing a collection
   * @param {Object} params An object of request parameters
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async list(params) {
    const result = await this.request({
      method: 'GET',
      path: this.path,
      params,
    });
    this.notifySubscribers(result, {});
    return result;
  }

  /**
   * A Symbol identifier alias for the get method
   * @param {object} params An object of request parameters
   * @param {string} params.id The "id" of the resource to get, which will be
   * included in the request URL like `/path/to/:id`
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async [ActionGet](params) {
    return this.get(params);
  }

  /**
   * A RESTful get operation, getting a single item from a collection
   * @param {object} params An object of request parameters
   * @param {string} params.id The "id" of the resource to get, which will be
   * included in the request URL like `/path/to/:id`
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async get(params) {
    params = { ...params };
    const id = params.id;
    delete params.id;
    const result = await this.request({
      method: 'GET',
      path: `${this.path}/${id}`,
      params,
    });
    this.notifySubscribers(result, { id });
    return result;
  }

  /**
   * A Symbol identifier alias for the update method
   */
  async [ActionUpdate]() {
    return this.update();
  }

  /**
   * A RESTful update operation, updating a single item in a collection
   * @todo Implement this method
   */
  async update() {
    throw new Error('not implemented');
  }

  /**
   * A Symbol identifier alias for the delete method
   * @param {object} params An object of request parameters
   * @param {string} params.id The "id" of the resource to get, which will be
   * included in the request URL like `/path/to/:id`
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async [ActionDelete](params) {
    return this.delete(params);
  }

  /**
   * A RESTful delete operation, deleting a single item from a collection
   * @param {object} params An object of request parameters
   * @param {string} params.id The "id" of the resource to get, which will be
   * included in the request URL like `/path/to/:id`
   * @returns {Promise<MemoryCache.CacheItem>} The cached or freshly-fetched response
   */
  async delete(params) {
    const id = params.id;
    delete params.id;

    const result = await this.request({
      method: 'DELETE',
      path: `${this.path}/${id}`,
      params,
    });
    this.invalidate({});
    return result;
  }

  invalidate(params) {
    this.cache.unset(
      this.serializeRequest({ method: 'GET', path: this.path, params: {} }),
    );
    this.cache.unset(
      this.serializeRequest({ method: 'GET', path: this.path, params: params }),
    );
    this[ActionList](params);
  }

  /**
   * @param {*} data The data to send to each subscriber
   * @param {object} params Params to match only the correct subscriber
   */
  notifySubscribers(data, params) {
    this.subscribers.forEach((s) => {
      if (!s.id) {
        s.callback(data);
        return;
      }

      if (s.id === params.id) {
        s.callback(data);
        return;
      }
    });
  }

  /**
   * Subscribe to changes from the endpoint
   * @param {Object} opts Options for the subscription, such as only subscribing to one ID from an endpoint
   * @param {string} opts.id The specific ID to subscribe to updates from, used for ActionGet on a specific thing
   * @param {function(Object): void} cb A callback that will receive updated data
   * @returns {function(): void} A function that can be called to cancel the subscription
   */
  subscribe(opts, cb) {
    this.subscribers.push({
      ...opts,
      callback: cb,
    });

    let poll;
    if (this.poll) {
      poll = setInterval(() => {
        if (!this.isCached(opts)) {
          this.list(opts);
        }
      }, 1000);
    }

    return () => {
      this.subscribers.forEach((s, idx, src) => {
        if (s.callback === cb) {
          src.splice(idx, 1);

          if (poll) {
            clearInterval(poll);
          }
        }
      });
    };
  }

  /**
   *
   * @param {Object} params request parameters
   * @returns {Boolean} true or false
   */
  isCached(params) {
    const key = this.serializeRequest({
      method: 'GET',
      path: this.path,
      params,
    });

    return this.cache.valid(key);
  }

  /**
   * @param {Object} params serializeRequest options
   * @returns {MemoryCache.CacheItem} A cached response
   * @throws {Error}
   */
  readCache(params) {
    const key = this.serializeRequest({
      method: 'GET',
      path: this.path,
      params,
    });

    if (this.isCached(params)) {
      return this.cache.get(key);
    }

    return { loading: false, data: null, error: null, expires: 0 };
  }

  /**
   * @param {{method: String, path: String, params: Object}} opts The required parameters to serialize a request
   * @returns {String} a serialized string representing a given HTTP request
   */
  serializeRequest({ method, path, params }) {
    return `${method}+${this.formatPath(path, params)}`;
  }
}

const requestCache = new MemoryCache();

/**
 * @type {Object<string, Endpoint>}
 */
const Endpoints = {
  MSSL: new Endpoint({
    path: '/api/mssl/tickets',
    cache: requestCache,
    TTL: 60,
    poll: false,
  }),
  Subscriptions: new Endpoint({
    path: '/api/shopper/subscriptions',
    cache: requestCache,
    TTL: 60,
  }),
  SubscriptionsQuery: new Endpoint({
    path: '/api/shields/subscriptions/search',
    cache: requestCache,
    TTL: 60,
  }),
  Domains: new Endpoint({
    path: '/api/meta/domains',
    cache: requestCache,
    TTL: 120,
  }),
  Sitecheck: new Endpoint({
    path: '/api/sitecheck/site',
    cache: requestCache,
    TTL: 120,
  }),
  MonitoringSites: new Endpoint({
    path: '/api/monitoring/sites',
    cache: requestCache,
    TTL: 120,
  }),
  BackupSites: new Endpoint({
    path: '/api/backups/sites',
    cache: requestCache,
    TTL: 120,
  }),
  BackupSite: new Endpoint({
    path: '/api/backups/sites/{siteId}/{path}',
    cache: requestCache,
    TTL: 120,
  }),
  LatestBackup: new Endpoint({
    path: '/api/backups/sites/{siteId}/latest',
    cache: requestCache,
    TTL: 120,
  }),
  BackupDates: new Endpoint({
    path: '/api/backups/sites/{siteId}/dates',
    cache: requestCache,
    TTL: 120,
  }),
  BackupRevision: new Endpoint({
    path: '/api/backups/sites/{siteId}/revision',
    cache: requestCache,
    TTL: 120,
  }),
  BackupTables: new Endpoint({
    path: '/api/backups/sites/{siteId}/tables',
    cache: requestCache,
    TTL: 120,
  }),
  BackupSettings: new Endpoint({
    path: '/api/backups/sites/{siteId}/settings',
    cache: requestCache,
    TTL: 120,
  }),
  BackupDays: new Endpoint({
    path: '/api/backups/sites/{siteId}/days',
    cache: requestCache,
    TTL: 120,
  }),
  BackupScheduler: new Endpoint({
    path: '/api/backups/sites/{siteId}/scheduler/{path}',
    cache: requestCache,
    TTL: 120,
  }),
  FirewallSites: new Endpoint({
    path: '/api/firewall/sites',
    cache: requestCache,
    TTL: 120,
  }),
  FirewallDomainConnect: new Endpoint({
    path: '/api/firewall/domain_connect',
    cache: requestCache,
    TTL: 60,
  }),
  ActiveSupportTicket: new Endpoint({
    path: '/api/support/tickets/active',
    cache: requestCache,
    TTL: 120,
    poll: false,
  }),
};

export default Endpoints;
export {
  ActionGet,
  ActionList,
  ActionUpdate,
  ActionDelete,
  Endpoints,
  Endpoint,
};
